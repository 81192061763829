<template>
  <div id="page-user-view">
    <div class="vx-row flex items-center mb-4">
      <div class="vx-col w-full flex justify-end" v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'finance'">
        <vs-button
          @click="newFulfillmentPackage()"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large"
        >
        </vs-button>
      </div>
    </div>
    <shipblu-table
     :sst="true"
      :max-items="maximumItems"
      pagination
      :data="FulfillmentPackages"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >

      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('Google Sheet URL')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'admin'" class="flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td class="order-3" :data="data[indextr].name">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Name')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].name }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].google_sheet_url">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Google Sheet URL')}}</p>
            <p class="sm:text-base text-sm">
              <a :href="data[indextr].google_sheet_url" target="blank">{{$t('Open in a new tab')}}</a>
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].status">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].status }}
            </p>
          </shipblu-td>

          <shipblu-td v-if="$store.state.AppActiveUser.userRole === 'admin'" class="order-4" style="vertical-align: middle;">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="deleteFulfillmentPackage(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-pricing-package :type="type" :pricingPackageModal="pricingPackageModal" @pricingPackageModal="pricingPackageModal = $event" :prcingPackage="fulfillmentPackage"
    @addPricingPackage="addFulfillmentPackage" @loadPricingPackages="loadFulfillmentPackages"></add-pricing-package>
  </div>
</template>

<script>
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import i18nData from '../../i18n/i18nData.js'
import AddPricingPackage from './components/AddPricingPackage.vue'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      fulfillmentPackage: {
        name: '',
        google_sheet_url: ''
      },
      type: 'Add Pricing Package',
      FulfillmentPackages: [],
      deleteData: {},
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      totalRows: 0,
      offset: 0,
      tableLoader: false,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      pricingPackageModal: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-accounts-pricing-packages`,
        query: {
          tab: this.$route.query.tab,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadFulfillmentPackages()
    }
  },
  methods: {
    loadFulfillmentPackages () {
      this.tableLoader = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/pricing-packages/?limit=${this.maximumItems}&offset=${this.offset}`, 'get', null, true,
        (response) => {
          this.FulfillmentPackages = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    newFulfillmentPackage () {
      this.pricingPackageModal = true
      this.fulfillmentPackage = {
        name: '',
        google_sheet_url: ''
      }
    },
    addFulfillmentPackage () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/pricing-packages/', 'post', this.fulfillmentPackage, true,
        () => {
          this.pricingPackageModal = false
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Pricing Package'] + i18nData[this.$i18n.locale][' has been created successfully!'],
            position: 'top-center'
          })
          this.loadFulfillmentPackages()
        }
      )
    },
    deleteFulfillmentPackage (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/pricing-packages/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Pricing Package'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadFulfillmentPackages()
        }
      )
    }
  },
  components: {
    AddPricingPackage,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadFulfillmentPackages()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>

<style lang="scss">
.con-pagination-table{
  display: none;
}
</style>